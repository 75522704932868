<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.size_x')"
                :placeholder="$t('labels.size_x')"
                name="size_x"
                sort-name="size_x"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.size_y')"
                :placeholder="$t('labels.size_y')"
                name="size_y"
                sort-name="size_y"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.size_z')"
                :placeholder="$t('labels.size_z')"
                name="size_z"
                sort-name="size_z"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                :label="$t('labels.max_load_capacity_each_cell')"
                :placeholder="$t('labels.max_load_capacity_each_cell')"
                name="max_load_capacity_each_cell"
                sort-name="max_load_capacity_each_cell"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!--<th role="columnheader">
            <InputFilter :label="$t('labels.warehouse')" :placeholder="$t('labels.warehouse')" name="warehouse" sort-name="warehouse" :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>-->
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.stamp_template')"
                :placeholder="$t('labels.stamp_template')"
                name="stamp_template"
                sort-name="stamp_template"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.stamp_font_size')"
                :placeholder="$t('labels.stamp_font_size')"
                name="stamp_font_size"
                sort-name="stamp_font_size"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <v-btn
                color="success"
                small
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td>
              <v-text-field
                v-model="item.name"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.size_x"
                :options="numberOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.size_y"
                :options="numberOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.size_z"
                :options="numberOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.max_load_capacity_each_cell"
                :options="numberOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <!--<td>
            <v-text-field v-model="item.warehouse" :disabled="!item.editing" class="c-input-small"
                          outlined dense hide-details single-line></v-text-field>
          </td>-->
            <td>
              <v-autocomplete
                v-model="item.stamp_template"
                :items="stampSizeOptions"
                :disabled="!item.editing"
                class="c-input-small"
                dense
                outlined
                hide-details
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                v-model.number="item.stamp_font_size"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                dense
                outlined
                hide-details
              ></v-text-field>
            </td>
            <td class="py-1">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                  >{{ $t("labels.edit") }}</v-btn
                >
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                  >{{ $t("labels.save") }}</v-btn
                >
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                  >{{ $t("labels.cancel") }}</v-btn
                >
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { NUMBER_OPTIONS, STAMP_SIZE_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "ListCellType",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {},
    isLoading: false,
    numberOptions: { ...NUMBER_OPTIONS },
    stampSizeOptions: [...STAMP_SIZE_OPTIONS],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        name: null,
        size_x: null,
        size_y: null,
        size_z: null,
        max_load_capacity_each_cell: null,
        // warehouse: null,
        stamp_template: null,
        stamp_font_size: null,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/warehouse/v1/save-cell-type", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/warehouse/v1/cell-type-list", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((item) => ({
            ...item,
            editing: false,
          }));
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
